module.exports = class Parallax {
  constructor() {
    this.parallaxes = $('.js-parallax');
    this.init();
  }

  init() {
    this.parallaxes.parallax();
  }
}
