module.exports = class Contact {
	constructor() {
		this.form = $('.js-form');
		this.sending = $("#sending");
		this.sent = $('.contact-form-sent-container');
		this.sentError = $('.contact-form-sent-error-container');

		this.init();
	}

	init(){
		this.form.on('submit', e => {
			e.preventDefault();
			
			let _url = "/php/send-mail.php";
			let _data = this.form.serialize();

			console.log(_data);
			this.sending.show();

			$.ajax({
					url: _url,
					type: 'POST',
					data: _data
			})
			.done(response => {
					console.log(response);
					if(response === "success"){
							this.sent.show();
					}
					else{
							this.sentError.show();
					}
			})
			.fail(() => {
					this.sentError.show();
			})
			.always(() => {
					this.form.hide();
					this.sending.hide();
			});
		});
	}
}