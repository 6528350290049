import Menu from './components/menu.js';
import Gallery from './components/gallery.js';
import Parallax from './components/parallax.js';
import Contact from './components/contact.js';

//jQuery wrapper

$(() => {
  new Menu();
  new Gallery();
  new Parallax();
  new Contact();
  
});
