module.exports = class Menu {

  constructor() {

    this.navToggle = $('.nav-toggle');
    this.navMenu = $('.nav-menu');
    this.navItems = $('.nav-menu a[href*="#"]:not([href="#"])');

    this.init();

  }

  init() {
    this.navToggle.on('click', () => {
      this.navMenu.toggleClass('is-active');
      this.navToggle.toggleClass('is-active');
    });

    this.navItems.on('click', function(){
      //$('.main-nav__button').toggleClass('main-nav__button--is-active'); //mobile only
      //$('.main-nav').toggleClass('main-nav--is-active');
      if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          $('html, body').animate({
            scrollTop: target.offset().top - 78
          }, 500);
          //return false;
        }
      }
    });
  }
}
